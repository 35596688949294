//
//
//
//
//
//
//
//
//

import buttonStyleMixin from '../mixins/button-style';

export default {
  name: 'FCButton',
  mixins: [buttonStyleMixin],
  props: {
    loading: Boolean,
    disabled: Boolean
  },
  methods: {
    onClick() {
      this.$emit('onClick');
    }
  }
};
