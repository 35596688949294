//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { FCInput } from '@fc/angie-ui';
import FCButton from '@/shared/components/FCButton.vue';
import { EMAIL_VALIDATOR } from '~/constants/regex';

export default {
  name: 'EmailBrochureForm',
  components: {
    FCInput,
    FCButton,
  },
  data() {
    return {
      email: '',
      emailError: false,
      emailErrorMsg: '',
    };
  },
  methods: {
    getLinkName(name) {
      return `${this.$options.name}_${name}`;
    },
    validateEmail() {
      this.emailErrorMsg = '';
      if (this.email === '') {
        return;
      }
      this.emailError = !EMAIL_VALIDATOR.test(String(this.email).toLowerCase());
      if (this.emailError) {
        this.emailErrorMsg = 'Email format invalid.';
      }
    },
    async handleSubmit() {
      this.validateEmail();
      if (this.emailError) {
        return;
      }
      if (this.email === '') {
        this.emailError = true;
        this.emailErrorMsg = 'Please enter your email.';
        return;
      }

      try {
        const res = await this.$api_client.validateEmail(this.email.toLowerCase());
        if (!res.emailValid) {
          throw new Error('Invalid Email. Please check for errors or try with another email.');
        }
        this.$ecommAnalytics.identifyByEmail(this.email);
        this.$analytics.emailIdentified(this.email);
        this.$bugsnag.setUser({ email: this.email });

        this.$ecommAnalytics.brochureFooter('Subscribe');
        this.$analytics.campaignSubscribed({
          name: 'BrochureFooter',
          type: 'footer',
          email: this.email,
        });

        this.$emit('handleSubmission');
        await this.$store.dispatch('identifyByEmail', this.email).catch(console.error);
      } catch (error) {
        this.emailError = true;
        this.emailErrorMsg = error.message;
      }
    },
    resetError() {
      this.emailError = false;
      this.emailErrorMsg = '';
    },
  },
};
